export enum Duration {
  SHORT = 15,
  MEDIUM = 30,
  LONG = 45,
}

export const durationLabels: Record<Duration, string> = {
  [Duration.SHORT]: 'Up to 15 min',
  [Duration.MEDIUM]: '15-30 min',
  [Duration.LONG]: 'Over 30 min',
};
